<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/paramSetting' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="处理报修信息"
                          placement="bottom"
                        >
                          <el-button
                            v-if="systemConfig.repairsStatus == 0"
                            type="primary"
                            size="small"
                            @click="submitForm('systemConfig')"
                            >点击处理</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button
                            @click="goIndex()"
                            type="primary"
                            size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="systemConfig"
                  ref="systemConfig"
                  label-width="150px"
                  class="systemConfig"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="systemConfig.id"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="地址" prop="address">
                        <el-input
                          v-model="systemConfig.address"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="设备编号" prop="deviceId">
                        <el-input
                          v-model="systemConfig.deviceId"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="问题详情" prop="malfunctionInfo">
                        <el-input
                          v-model="systemConfig.malfunctionInfo"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="处理状态" prop="repairsStatus">
                        <el-select
                          v-model="systemConfig.repairsStatus"
                          :disabled="true"
                        >
                          <el-option label="未处理" :value="0"></el-option>
                          <el-option label="已处理" :value="1"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item
                        label="反馈图片"
                        prop="deviceImage"
                        style="display:flex;flex-flow:wrap"
                      >
                        <!-- <el-input v-model="systemConfig.deviceImage"></el-input> -->
                        <template v-for="(item, index) in imgList">
                          <el-image
                            :key="index"
                            style="width: 200px; height:200px"
                            :src="item"
                            :preview-src-list="[item]"
                            fit="cover"
                            @click.stop="handleClickItem"
                          />
                        </template>

                        <!-- <el-image
                          style="width: 200px; height: 200px"
                          :src="imgList[0]"
                          :preview-src-list="imgList"
                          fit="cover"
                          @click.stop="handleClickItem"
                        >
                        </el-image> -->
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      srcList: [],
      // 当前页数
      currPage: 1,
      pageSize: 10,
      page: 1,
      limit: 10,
      seeThis: 0,
      loading: true,
      id: "",
      systemConfig: {
        id: "",
        address: "",
        deviceId: "",
        deviceImage: "",
        malfunctionInfo: "",
        repairsStatus: "1"
      },
      imgList: []
    };
  },
  methods: {
    //图片预览点击遮罩层关闭
    handleClickItem() {
      // 获取遮罩层dom
      let domImageMask = document.querySelector(".el-image-viewer__mask");
      if (!domImageMask) {
        return;
      }
      domImageMask.addEventListener("click", () => {
        // 点击遮罩层时调用关闭按钮的 click 事件
        document.querySelector(".el-image-viewer__close").click();
      });
    },
    goIndex() {
      this.$router.go(-1);
    },
    //提交功能
    submitForm(formName) {
      var that = this;
      //   alert()
      that.systemConfig.repairsStatus = 1;
      that.$http
        .post("/repairsInfo/save", {
          repairsStatus: 1,
          id: that.id,
          deviceId: that.systemConfig.deviceId,
          address: that.systemConfig.address,
          deviceImage: that.systemConfig.deviceImage
        })
        .then(function(response) {
          that.$notify.success({
            title: "提示",
            message: "保存成功",
            showClose: true
          });
          that.$router.go(-1);
        })
        .catch(function(error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true
          });
        });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/repairsInfo/list", {
            page: 1,
            limit: 10,
            condition: {
              id: that.id
            }
          })
          .then(function(response) {
            // console.log("---------");
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.systemConfig = response.data.data.data[0];
              that.imgList = response.data.data.data[0].deviceImage.split(";");
              //  console.log("122"+response.data.data.data[0])
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      //   var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      //   if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
      //     this.seeThis = 0;
      //   }
      //   if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
      //     this.seeThis = 1;
      //   }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    }
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      // that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  }
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
